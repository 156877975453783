export default {
  "404": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore 404 - Pagina non trovata"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il contenuto che state cercando non è stato trovato"])}
  },
  "footer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A-Wallet - Open source PWA Algorand community wallet"])}
  },
  "login": {
    "new_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo wallet"])},
    "new_wallet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del wallet"])},
    "new_wallet_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password del wallet"])},
    "new_wallet_button_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un wallet"])},
    "new_wallet_button_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa un wallet"])},
    "new_wallet_help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo wallet sarà salvato localmente nel tuo browser. La password del wallet è necessaria per accedervi e per firmare le transazioni."])},
    "new_wallet_help2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si raccomanda di usare una password di almeno 12 caratteri, usando lettere minuscole, maiuscole, numeri e caratteri speciali"])},
    "open_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apri il wallet"])},
    "select_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il wallet"])},
    "wallet_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password del wallet"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robustezza"])},
    "new_wallet_button_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apri il wallet"])},
    "basic_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic usage"])},
    "tether_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to use Tether  (USDt)"])},
    "source_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWallet source code"])}
  },
  "global": {
    "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
    "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna alla Home"])},
    "copy_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia indirizzo"])},
    "copied_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiato negli appunti"])},
    "stop_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop camera"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading.."])},
    "keyword_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword Search"])}
  },
  "navbar": {
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Wallet"])},
    "toggle_nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia navigazione"])},
    "list_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra i miei account"])},
    "new_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo account"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
    "asset_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creat NTF"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "vote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voting"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SWAP"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect"])}
  },
  "swap": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap assets"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
    "network_not_supported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network is not supported"])},
    "has_sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only standard accounts with private keys are supported now"])},
    "swap_asset_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select asset from which you want to swap"])},
    "swap_asset_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select asset to which you want to swap"])},
    "swap_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set the amount to swap"])},
    "get_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get quote"])},
    "apps_optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SWAP requires your account to opt in to the applications"])},
    "apps_optin_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in to the apps"])},
    "execute_button_deflex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execute using Deflex"])},
    "execute_button_folks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execute using Folks router"])},
    "slippage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slippage percentage"])}
  },
  "govtoolsmenu": {
    "gen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate accounts"])},
    "distribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribute algo 1->N"])},
    "optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in for the governance"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mass pay N->1"])},
    "merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merge accounts N->1"])}
  },
  "merchant": {
    "make_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment gateway"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
    "to_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to the algorand address"])},
    "please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
    "matching_symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matching symbol"])},
    "network_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network fee"])},
    "pay_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay using QR code"])},
    "pay_nativewallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay using algorand wallet"])},
    "pay_webwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay using web wallet"])},
    "cancel_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel payment"])},
    "payment_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment received"])},
    "go_back_to_merchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back to merchant"])},
    "waiting_for_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for the payment"])},
    "error_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error fetching asset: "])}
  },
  "gateway": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free opensource payment gateway without middleman"])},
    "perex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start accepting algo, USDC, USDT or other assets on your website. By accepting cryptocurrency without financial middleman you will increase your sales on your website and increase your profit."])},
    "how": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works?"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create unique matching symbol, eg. order id"])},
    "redirect_to_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect user to the gateway"])},
    "customer_pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer pays directly to your algorand account"])},
    "customer_redirected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer will be redirected to your website back after successful payment"])},
    "use_your_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use your money. You can echange USDT and take USD from the exchange, or you can pay with USDT for other goods or services"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is it secure?"])},
    "you_make_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You make the payment details. You setup the successful and cancel URL address. We notify you when the payment is done. You have to check in your backend where you accept the payment that it has been received and you notify the customer."])},
    "opensource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This project is open source. You can clone, modify it, or run in your private environment for commercial purposes"])},
    "just_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment gateway is just HTML website with javascript. No other backend is used other than official algorand nodes."])},
    "no_restrictions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have AWallet account in order to use the gateway."])},
    "allowed_parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed parameters"])},
    "parameters_perex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can set any of these parameters. Only required field is the address where you want to receive your payment and transaction amount. The payment address consits of 2 parts. Payment info, and gateway settings. Both fields are base64 encoded separated by /. Please make sure that you url encode them properly. Payment info is the basic algorand protocol address used for example in the QR codes."])},
    "payment_info_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed parameters"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["use the asset base unit. Eg. 1 ALGO = 1000000"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the amount in microalgos. Eg. 0,001 ALGO = 1000"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network for debug purposes"])},
    "settings_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed settings"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL address where to redirect client after successful payment"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL address where to redirect client after he clicks cancel payment"])},
    "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example"])},
    "turn_on_build_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn on the build tool"])},
    "turn_off_build_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn off the build tool"])},
    "tether": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tether mainnet"])},
    "algorand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorand"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can specify asset id for the transaction"])},
    "error_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in at least account, amount and asset"])},
    "error_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL address must start with https:// protocol"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generated button"])},
    "payment_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment info"])},
    "callback_configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback configuration"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generated link"])},
    "html_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML link example"])}
  },
  "receive": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevi pagamento"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevi pagamento su un indirizzo"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sulla posizione dei decimali per il QR Code"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichetta suggerita per l'indirizzo ricevente"])},
    "noteeditable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le note sono modificabili dall'utente"])}
  },
  "acc_overview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteprima Account"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo pagamento"])},
    "receive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevi pagamento"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
    "amount_without_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo senza le ricompense in attesa"])},
    "rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricompense"])},
    "pending_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricompense in attesa"])},
    "reward_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricompensa base"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Round"])},
    "apps_local_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps local state"])},
    "apps_total_schema": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps total schema"])},
    "assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assets"])},
    "created_apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicazioni create"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
    "multisignature_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soglia per la firma multipla"])},
    "multisignature_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzi per firma multipla"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazioni"])},
    "no_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna transazione trovata"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "tr_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittente"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatario"])},
    "receiver_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricompensa destinatario"])},
    "confirmed_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Round confermato"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
    "delete_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
    "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this account from your wallet?"])},
    "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this account"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account has been deleted"])},
    "asset_optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in to asset"])},
    "ask_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask question"])},
    "rekey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekey account"])},
    "rekeyedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekeyed to account"])},
    "account0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ledger primary account"])},
    "slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account at ledger slot"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect"])},
    "payment_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment gateway"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
    "no_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no assets yet on this account"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "pay_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send money or tokens to other accounts in the network."])},
    "swap_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can swap one token to another using DEX aggregator services"])},
    "rekey_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are afraid that your account private key may be compromise, you can rekey your account to new private key and keep your original address safe."])},
    "asset_optin_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to receive asset on algorand network you have to open the account for that token."])},
    "receive_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can ask your customers or friends to send you money. This page will generate payment QR code so that others can do it on one click."])},
    "export_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can generate shamir backup mnemonics or algorand mnemonic for accounts with private keys."])},
    "arc14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC 14 Authentication"])},
    "arc14_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC 14 is authentication standard utilizing signed transactions. You can generate the authentication tokens on this page."])},
    "account_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account actions"])},
    "asset_optinArc200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OptIn to ARC200 asset"])},
    "asset_optinArc200_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can watch on your account the ARC200 assets. ARC200 assets are smart contracts with feature that anyone can opt in user's account to be able to receive the asset. ARC200 assets may be dangerous and you should require trustworthy audit of the smart contract so that you can fully trust it."])},
    "scheduled_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled payment"])},
    "scheduled_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can setup and manage a scheduled periodic payments to another accounts"])}
  },
  "acc_overview_assets": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "search_by_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by name"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "search_by_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by asset type"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
    "search_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by asset id"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "search_by_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by amount"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
  },
  "optin": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept transfers of asset in account"])},
    "searchById": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I know the asset ID"])},
    "assetId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset ID"])},
    "assetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset Name"])},
    "searchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find asset"])},
    "assetInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about the asset"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "unit_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit name"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimals"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "metadata_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata hash"])},
    "default_frozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default frozen"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator address"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager address"])},
    "clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clawback address"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeze address"])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve address"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "optin_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in"])},
    "dialog_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in to asset"])},
    "optin_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to pay 0.001 algo to opt in to this asset? After you opt in, you will be able to receive this asset to your account."])},
    "optin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to opt in"])},
    "asset_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset not found"])},
    "asset_opt_in_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully opt in for the asset"])}
  },
  "accounts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteprima Account"])},
    "no_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun account trovato"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome account"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect"])},
    "show_on_netowork_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show accounts for network"])},
    "2fa_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA code"])}
  },
  "newacc": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo account"])},
    "create_basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un account di base"])},
    "import_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa Account"])},
    "create_multisign_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea account multifirma"])},
    "watch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guarda account"])},
    "write_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trascrivi le 25 parole"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome interno account"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea account"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzi"])},
    "multisig_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un account multifirma può elaborare le transazioni solo se gli N account indicati alla creazione dell'account firmano la transazione."])},
    "select_account_from_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona account esistenti nel tuo wallet"])},
    "add_other_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi gli account degli amici - un account per riga"])},
    "trashold_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona quanti account sono necessari per firmare la transazione"])},
    "create_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per creare l'account, assicurati di avere salvato la frase mnemonica. È sicuro mostrare la frase adesso?"])},
    "show_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra la frase mnemonica"])},
    "position_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qual è la parola alla posizione n."])},
    "mnemonic_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scrivi la frase mnemonica nell'ordine mostrato, copiala o fai uno screenshot e salvala in un luogo sicuro e protetto. Non mostrare la frase a nessuno. Il passo successivo sarà quello di confermare che hai salvato la frase correttamente e dopo aver confermato la parola corretta di una posizione specifica salveremo quel conto in un archivio criptato nel tuo browser. Puoi usare questa frase anche senza salvarla, ad esempio in altri portafogli. Assicurati di non perderla mai, nemmeno fra 10 anni"])},
    "start_challenge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizia la verifica"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea nuova"])},
    "hide_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascondi menemonico"])},
    "drop_phrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarta frase"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan from QR"])},
    "create_vanity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create vanity account"])},
    "vanity_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address will start with"])},
    "vanity_mid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address will contain"])},
    "vanity_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address will end with"])},
    "vanity_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of addresses processed"])},
    "vanity_button_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find the address"])},
    "vanity_button_stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop searching"])},
    "vanity_workers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of threads to use"])},
    "vanity_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use this account"])},
    "auto_lock_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto lock feature is turned off"])},
    "ledger_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HW Wallet - Ledger Account"])},
    "wc_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet Connect Account"])},
    "twofa_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA Account"])},
    "show_qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show QR Code"])},
    "email_pass_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email & Password account"])},
    "shamir_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir backup recovery"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New account actions"])},
    "create_basic_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorand basic account is ED25519 account with private key. This method uses your computer random number generator to create secret for your account."])},
    "ledger_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One of most secure solutions is to handle the secret key generation by specialized device. This account utilizes Ledger device to create your account and handle the signing operations."])},
    "wc_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can connect to your friend's wallet using the wallet connect account type. You can send them request to sign the message for example as part of multisig."])},
    "twofa_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two factor account is multisig account, where one account is account of your choosing for example ledger account or email password account, second account is recovery account - ideally account which is not in the same secure storage, and third account is the two factor account from the server which signs transactions when you provide correct two factor pin code."])},
    "email_pass_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC76 Email & Password account allows you to generate algorand account from any text. The text is hashed and generates 32 bytes as seed for your algorand account. This may be alternative to other types of random number generators in your computer or hw device which you can utilize in two factor account or generic multisig account."])},
    "create_vanity_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can brute force nice account address for your ED25519 account. This method simply tries a lot of combinations of creating account and lets you pick first or last letters of the account address."])},
    "create_multisign_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisig accounts are the most secure accounts. If used in combination of multiple ledger devices and different random number generators, this provides enterprise level of security which is hardcoded directly to the algorand blockchain."])},
    "watch_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can track any account on the network."])},
    "import_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can recover the account from the algorand recovery mnemonic."])},
    "shamir_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can recover the account from the shamir backup recovery mnemonics."])},
    "create_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your first account"])},
    "no_accounts_at_network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no accounts for selected network"])},
    "basic_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic options"])},
    "advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced options"])},
    "skip_challange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip challange"])},
    "new_account_challange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New account challange"])},
    "click_to_show_positions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to show positions"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])}
  },
  "arc76account": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email and password account (ARC-0076)"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash of combination of your email and your password will generate seed bytes for your first algorand account. You can change the password or email only by rekeying to another account."])},
    "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC-0076 standard will be used with algorithm PBKDF2-999999-SHA256. We recommend the password length to be at least 50 characters long. This type of account may be served as secondary type of random number generator and may strengthen your security. Please keep the secret secure and do not reuse it."])},
    "arc_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC-0076 is in the DRAFT state. The specification can change. Please consider this implementation for experimental usage."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "select_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select very very very secure password."])},
    "save_password_switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save password"])},
    "password_not_stored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch out. We do not store your password. We will store only the account public address and email to the secure storage. When you will be doing transactions with this account you will be prompted to enter the password."])},
    "gui_not_implemented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GUI for signing transaction without stored password is not currently implemented."])}
  },
  "import": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa wallet"])},
    "wallet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del Wallet"])},
    "wallet_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File del Wallet"])},
    "import_wallet_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa wallet"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi importare un wallet precedentemente esportato. La password del wallet rimane all'interno del file esportato, quindi puoi accedervi dopo l'importazione con la stessa password di prima. Puoi cambiare la password dopo il login dalla pagina delle impostazioni."])}
  },
  "acc_type": {
    "basic_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account di base"])},
    "multisig_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account multifirma"])},
    "public_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account pubblico"])},
    "rekeyed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekeyed account"])},
    "ledger_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HW ledger account"])},
    "wc_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect account"])}
  },
  "account": {
    "select_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select account"])}
  },
  "pay": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effettua pagamento - da"])},
    "toggle_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva fotocamera"])},
    "note_is_b64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le note sono codificate in Base64. Se abilitato, puoi inserire direttamente Base64 nel campo della nota e inviarlo come dato grezzi alla rete."])},
    "selected_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona account"])},
    "multisig_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account multifirma"])},
    "create_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea proposta"])},
    "sign_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma e invia la proposta"])},
    "signature_from_friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore inserisci qui la firma dell'amico"])},
    "load_multisig_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica dati multifirma"])},
    "pay_to_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga ad un account del wallet"])},
    "pay_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga ad un altro account"])},
    "store_other_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È preferibile memorizzare il conto a cui si inviano soldi nella rubrica"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "review_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica pagamento"])},
    "review_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore verifica il pagamento"])},
    "from_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dall'account"])},
    "pay_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga all'account"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])},
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli transazione"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "first_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primo Round"])},
    "last_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo Round"])},
    "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis ID"])},
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichetta"])},
    "to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A"])},
    "signatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firme"])},
    "signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmato"])},
    "not_signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non firmato"])},
    "sign_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma con"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
    "send_to_other_signators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia questi dati agli altri firmatari"])},
    "send_to_network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia alla rete"])},
    "state_sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sto inviando il pagamento nella rete"])},
    "state_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento inviato nella rete. Tx: "])},
    "state_waiting_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In attesa di conferma dalla rete."])},
    "state_confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma ricevuta. Il tuo pagamento è nella blockchain"])},
    "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazione"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
    "state_error_not_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probabilmente il pagamento non ha raggiunto la rete. Sei offline? Per favore controlla il tuo account"])},
    "set_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set maxium"])},
    "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environment"])},
    "pay_to_address_malformed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Pay To seems to be malformed."])},
    "asset_failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It seems that desired asset has failed to load"])},
    "asset_too_small_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It seems your balance is below desired pay amount"])},
    "rekey_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekey to account"])},
    "rekey_to_wallet_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekey to wallet account"])},
    "rekey_to_external_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekey to external account"])},
    "rekey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekey account"])},
    "rekey_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please take a special attention to this transaction. From now on, the account will use different private key to be able to do the transactions. If you do not have private key of the new account, you will most likely loose ability to sign transactions."])},
    "combine_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combine signatures from other signator"])},
    "combine_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combine signatures"])},
    "return_to_wc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to WalletConnect"])},
    "select_account_for_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select account from which you want to make the payment"])},
    "create_multisig_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create multisig proposal"])},
    "2fa_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA code"])},
    "sign_arc14_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign authorization request"])},
    "toggle_send_to_others_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle Send to others form"])},
    "toggle_combine_with_others_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle Combine with others form"])},
    "process_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process payment"])},
    "tx_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction type"])}
  },
  "settings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])},
    "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server"])},
    "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambiente"])},
    "mainnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mainnet"])},
    "testnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testnet"])},
    "devnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reach Devnet"])},
    "sandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandbox"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["host"])},
    "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["token"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passord del wallet"])},
    "oldpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vecchia password"])},
    "newpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password"])},
    "repeatpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password (ripeti)"])},
    "backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup del wallet"])},
    "backup_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi fare un backup del wallet e importarlo in un altro computer."])},
    "create_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea backup"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancella il wallet"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robustezza"])},
    "updated_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password è stata aggiornata"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom server settings"])},
    "protocol_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register protocol handler"])},
    "protocol_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register web+algorand:// handler"])},
    "protocol_change_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully updated the protocol handler"])},
    "dev_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show information for developers"])},
    "update_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update password"])}
  },
  "transaction": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli transazione"])},
    "tr_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID transazione"])},
    "tr_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo transazione"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "note_raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati grezzi"])},
    "note_decoded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati decodificati"])},
    "tr_close_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi ricompense"])},
    "closing_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi importo"])},
    "confirmed_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Round confermato"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissioni"])},
    "first_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primo valido"])},
    "genesis_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Genesis"])},
    "genesis_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash Genesis"])},
    "intra_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offset intra round"])},
    "last_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo valido"])},
    "payment_tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazione di pagamento"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
    "close_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi importo"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatario"])},
    "receiver_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricompensa destinatario"])},
    "round_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo del Round"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittente"])},
    "sender_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricompensa mittente"])},
    "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
    "created_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset creato"])},
    "asset_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome asset"])},
    "asset_creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autore Asset"])},
    "asset_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager Asset"])},
    "asset_reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riserva Asset"])},
    "asset_freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congela Asset"])},
    "asset_clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoca Asset"])},
    "asset_unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome Asset unit"])},
    "asset_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale Asset"])},
    "asset_decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimali Asset"])},
    "asset_defaultfrozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset predefinito congelato"])},
    "asset_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset url"])},
    "asset_metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset metadata"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group ID"])}
  },
  "privacy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quali informazioni private raccogliete su di me ?"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna. A parte l'indirizzo IP e il browser che viene memorizzato nei log di ogni servizio internet. Non raccogliamo alcun tipo di informazione privata come il tuo nome, email o indirizzo."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cosa memorizzate nei cookie?"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nulla. Non usiamo cookie. Usiamo un'archiviazione locale persistente chiamata indexdb per salvare il tuo wallet."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come raccogliete le informazioni analitiche?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non lo facciamo. Non usiamo nessun tipo di servizio di analisi, ad esempio Google Analytics, in modo che possiate sentirvi più sicuri. Il web è puramente html con javascript, e conosciamo il tuo indirizzo IP solo quando fai richieste al server. A seconda delle tue impostazioni e del tuo algod server o indexer, il server selezionato potrebbe anche conoscere il tuo indirizzo IP."])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'uso di questo servizio influisce sulle pubblicità che vedo?"])},
    "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. I server commerciali non hanno alcuna informazione da parte nostra riguardo all'utilizzo. L'unico modo in cui per esempio Google può sapere che stai usando questo servizio è che tu acceda a questo sito dalla ricerca di Google."])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi offre questo servizio?"])},
    "a5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scholtz & Company, jsa ha creato questo servizio. ID Azienda: 51 882 272, VAT ID: SK2120828105, con sede a Bratislava, Slovacchia.  Se questo è in esecuzione nel sito ufficiale a-wallet.net, la società è anche fornitore del servizio. La società non è responsabile di alcun servizio, né di eventuali errori nell'applicazione."])}
  },
  "assetcreate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo NTF / ASA"])},
    "show_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra form avanzato"])},
    "assetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome asset"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo autore"])},
    "hide_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascondi il form avanzato"])},
    "creator_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea o importa un indirizzo prima"])},
    "default_fronzen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congelato : Gli account degli utenti dovranno essere scongelati prima di effettuare transazioni"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimali: numero intero di decimali per il calcolo dell'unità dell'asset"])},
    "totalIssuance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero totale di circolante per questo asset"])},
    "unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizzato per visualizzare le unità di asset all'utente"])},
    "assetURL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stringa opzionale che punta a un URL relativo all'asset"])},
    "assetMetadataHash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash opzionale relativo all'asset. Base64 format."])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il manager può modificare il manager, la riserva, l'account di congelamento e di revoca per l'asset."])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo di riserva: L'indirizzo specificato è considerato la riserva dell'asset (non ha privilegi speciali, è solo informativo)"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo di congelamento: L'indirizzo specificato può congelare o scongelare gli asset dell'utente"])},
    "clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo di revoca: L'indirizzo specificato può revocare gli asset dell'utente e inviarli ad altri indirizzi"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota impostata nella transazione di creazione"])},
    "create_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea NFT / ASA"])}
  },
  "new_account_ledger": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Ledger account"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ledger is a physical device - HW wallet which can store your private key. The maximum security for storing the assets on algorand is multisig account with all signators secured by ledger devices."])},
    "last_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last error"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect ledger and load algorand address"])},
    "slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slot"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "primary_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary address"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account name"])},
    "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save the address to the wallet"])}
  },
  "faq": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domande frequenti"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riguardo al wallet"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'applicazione Algo wallet è stata inizialmente creata da Scholtz & Company, jsa. Open source, solo html, corporate friendly, e sicurezza sono i principali vantaggi del suo utilizzo."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come sono protetti i dati del mio wallet?"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I tuoi dati sono solo nel browser. Sono memorizzati in un database persistente. Ogni wallet è criptato usando aes256 con la tua password. Se scegli una password abbastanza forte, il tuo wallet non sarà mai forzato. La password non lascia il tuo computer e risiede solo nella cache del browser. Dopo 5 minuti dal login la cache viene distrutta."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password del mio wallet va su internet?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come posso cambiare la password del wallet?"])},
    "a4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effettua il login e accedi al tuo wallet"])},
    "a4_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai alle impostazioni."])},
    "a4_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digita la vecchia password, la nuova password e conferma la nuova password."])},
    "a4_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia il form e la tua password verrà cambiata."])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posso spostare il mio wallet su un altro computer?"])},
    "a5_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effettua il login e accedi al tuo wallet"])},
    "a5_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai alle impostazioni."])},
    "a5_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai alla sezione backup e salva i tuoi dati in un file locale."])},
    "a5_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nell'altro computer vai a nuovo wallet, importa wallet, e carica il file esportato nella memoria del nuovo browser."])},
    "a5_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi al wallet con la stessa password di prima. Puoi cambiare la password nelle impostazioni."])},
    "q6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posso controllare il tuo codice sorgente?"])},
    "a6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì. https://github.com/scholtz/wallet/ Questo wallet è stato reso open source ed è libero di essere usato, copiato o modificato anche per scopi commerciali. Se sei in grado di partecipare al progetto, sii così gentile da creare una richiesta di pull con i tuoi contributi."])},
    "q7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come posso aiutare il progetto?"])},
    "a7_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una donazione all'indirizzo Algorand AWALLETCPHQPJGCZ6AHLIFPHWBHUEHQ7VBYJVVGQRRY4MEIGWUBKCQYP4Y aiuterà il progetto ad andare avanti. Il progetto non è stato finanziato da Algorand inc né dalla fondazione Algorand."])},
    "a7_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per tradurre il progetto o aiutare con la traduzione, vai su https://github.com/scholtz/wallet/tree/master/src/locales, copia il file di traduzione nella tua lingua e fai una richiesta di pull."])},
    "a7_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se sei un programmatore, per favore fai una revisione del codice, o aiutaci in qualsiasi modo tu pensi che questo progetto possa essere migliorato."])},
    "q8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come fare un account multifirma?"])},
    "a8_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per prima cosa create gli account con le chiavi private o create account pubblici. È meglio dare loro dei nomi che vi ricorderanno il loro scopo."])},
    "a8_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andate su crea nuovo indirizzo e selezionate l'indirizzo multifirma."])},
    "a8_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona tutti i gli account che possono firmare le transazioni e seleziona quante firme sono necessarie come minimo perché la rete accetti il pagamento multifirma."])},
    "a8_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopo aver creato l'account multifirma, puoi accedervi dalla pagina dell'elenco degli account."])}
  },
  "connect": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect to DApps using WalletConnect"])},
    "uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect URI"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
    "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect"])},
    "sendBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send back to DApp"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "authaddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorized Address"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Index"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "rekeyto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekey To"])},
    "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method"])},
    "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client ID"])},
    "request_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request ID"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "peer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peer"])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected"])},
    "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions"])},
    "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
    "total_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Fee"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign"])},
    "clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect from clipboard"])},
    "sign_txs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please sign individual transactions"])},
    "signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signed"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validity"])},
    "rounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rounds"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Index"])},
    "app_args": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Args"])},
    "app_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Accounts"])},
    "app_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Foreign Assets"])},
    "boxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boxes"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis ID"])},
    "genesis_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis Hash"])},
    "session_proposals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session proposals"])},
    "toggle_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle camera"])},
    "init_wc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initialize connection to Wallet Connect"])},
    "sign_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign all"])},
    "proposer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proposer"])},
    "all_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use all wallet accounts"])}
  },
  "new_account_wc": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add WalletConnect account"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account name"])},
    "last_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last error"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan the QR code"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save the address to the wallet"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy URI to clipboard"])},
    "show_qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show QR code"])}
  },
  "new_account_2fa": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA Account"])},
    "help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA account allows you to create multisig account with 3 accounts setup with threshold of 2 required for signature."])},
    "help2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First account may be password protected account where in the case of the hack of your wallet the hacker cannot sign the transactions on your behalf. If your 2FA device gets broken, or 2FA service gets down, you can rekey your account to another 2FA multisig with your first and second account."])},
    "help3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA service is third party service which might be compromised. If hacker receives your private key and receives the private key of the 2fa service, he may sign transactions on your behalf. By using the 2fa service you increase chance that hacker who receive ability to sign transactions with your primary or recovery account is not able to sign transactions from your multisig account."])},
    "2fa_provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA provider"])},
    "hot_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hot account"])},
    "cold_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cold storage account"])},
    "request_qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request 2FA QR code"])},
    "scan_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please scan the QR code with your 2FA app"])},
    "manual_entry_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual entry key"])},
    "confirm_2fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm setup"])},
    "confirm_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please write 2FA code from your auth app to confirm the setup. After confirmation it is not possible to request the 2fa secret code again."])},
    "2fa_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA code"])},
    "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save address"])}
  },
  "onlineofflinedialog": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protect the network with your account's balance"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can select public participation node in order to verify the blocks in the network. You will generate new participation keys at the public algod server and sign the transaction to allow these participation keys collect blocks data. This will cost you as one transaction on the network (0.001 algo). Online account state does not mean that your private key is exposed to the internet. It means that your account is registered at the online algod node which is protecting blockchain."])},
    "makeOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make account online"])},
    "makeOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make account offline"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algod host"])}
  },
  "new_account_shamir": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir backup account recovery"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir backup is the method to split mnemonic into N parts where M accounts is the recovery threshold."])},
    "mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mnemonic"])},
    "add_mnemnic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add mnemonic"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the account"])},
    "recover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recover"])}
  },
  "account_export": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export account"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On this page you can export your private key in form of algorand's mnemonic or shamir backup."])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide your wallet password"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "algo_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorand mnemonic"])},
    "shamir_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir backup"])},
    "toggle_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle QR code"])},
    "recovery_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery threshold"])},
    "number_of_mnemonics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of mnemonics"])},
    "generate_shamir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate shamir mnemonics"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir Index"])},
    "shamir_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please secure mnemonic at very secure place. Make sure you write down all parts of shamir backup and do not store the recovery threshold at one place."])},
    "algo_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please secure mnemonic at very secure place."])},
    "dev_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developer info"])}
  },
  "arc200": {
    "optin_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add ARC 200 asset to account"])},
    "app_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC200 App ID"])},
    "fetch_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fetch asset information"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "examples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example assets"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbol"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimals"])},
    "supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total supply"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account balance"])},
    "is_opted_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account can receive asset"])},
    "create_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create box for this asset for this account"])},
    "save_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save asset to your account"])}
  },
  "scheduled_payments": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled payments management"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With decentralized scheduler you will deploy the smart contract - escrow account which will be used for purpose to allow any executor perform withdrawal from your escrow account to the destination account when the time comes. Executors are incentivized to execute your scheduled task with vision of receiving small fee paid in ASA.Gold token - 0.001 gram of gold."])},
    "new_scheduled_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New scheduled payment"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "pay_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay to"])},
    "send_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset to send"])},
    "create_escrow_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create escrow account"])},
    "list_of_payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of active payments"])},
    "no_payment_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No scheduled payments has been found"])},
    "app_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escrow app id"])},
    "app_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escrow app address"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "fee_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee balance"])},
    "execution_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution fee"])},
    "description_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this detail overview of the scheduled payment you can see the current asset balance of the escrow account and you have actions to deposit more assets to the escrow account or to executors."])},
    "details_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled payment details"])},
    "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seconds"])},
    "deposit_fee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit asa.gold token to fee pool smart contract"])},
    "fee_asset_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution fee token"])},
    "amount_to_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to deposit to fees"])},
    "get_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get more tokenized gold"])},
    "list_of_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of assets at escrow account"])},
    "assets_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset information is loading"])},
    "asset_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset ID"])},
    "asset_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset name"])},
    "asset_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset amount"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
    "optin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escrow account optin action"])},
    "optin_to_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in to asset"])},
    "optin_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in"])},
    "withdraw_asset_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw from escrow account"])},
    "withdraw_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset to withdraw"])},
    "withdraw_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to withdraw"])},
    "withdraw_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execute withdrawal"])},
    "load_script_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load app execution profile"])},
    "load_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load"])},
    "xgov_promo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This work has been performed with support from the Algorand Foundation xGov Grants Program"])}
  }
}