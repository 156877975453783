export default {
  "404": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error 404 - Pagina niet gevonden"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De pagina die je zoekt is niet gevonden"])}
  },
  "footer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A-Wallet - Open source PWA Algorand community wallet"])}
  },
  "login": {
    "new_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe portemonnee"])},
    "new_wallet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portemonnee name"])},
    "new_wallet_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portemonnee password"])},
    "new_wallet_button_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak portemonnee"])},
    "new_wallet_button_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importeer portemonnee"])},
    "new_wallet_help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw portemonnee wordt opgeslagen in uw browser. Portemonnee wachtwoord is vereist om uw portemonnee te openen en uw accounts hierin te zien of om een verrichting te tekenen."])},
    "new_wallet_help2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij adviseren een wachtwoord lengte van tenminste 12 characters, met het gebruik van kleine letters, hoofdletters een nummer en een speciaal teken."])},
    "open_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open portemonnee"])},
    "select_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer portemonnee"])},
    "wallet_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portemonnee wachtwoord"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sterkte"])},
    "new_wallet_button_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open portemonnee"])},
    "basic_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic usage"])},
    "tether_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to use Tether  (USDt)"])},
    "source_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWallet source code"])}
  },
  "global": {
    "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga terug"])},
    "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar homepagina"])},
    "copy_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieer adres"])},
    "copied_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekopieerd naar uw klembord"])},
    "stop_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop camera"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading.."])},
    "keyword_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword Search"])}
  },
  "navbar": {
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een portemonnee"])},
    "toggle_nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigatie schakel"])},
    "list_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon mijn accounts"])},
    "new_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw account"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activa"])},
    "asset_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak NTF"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veelgestelde Vragen"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy beleid"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen"])},
    "vote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voting"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SWAP"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect"])}
  },
  "swap": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap assets"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
    "network_not_supported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network is not supported"])},
    "has_sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only standard accounts with private keys are supported now"])},
    "swap_asset_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select asset from which you want to swap"])},
    "swap_asset_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select asset to which you want to swap"])},
    "swap_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set the amount to swap"])},
    "get_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get quote"])},
    "apps_optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SWAP requires your account to opt in to the applications"])},
    "apps_optin_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in to the apps"])},
    "execute_button_deflex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execute using Deflex"])},
    "execute_button_folks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execute using Folks router"])},
    "slippage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slippage percentage"])}
  },
  "govtoolsmenu": {
    "gen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate accounts"])},
    "distribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribute algo 1->N"])},
    "optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in for the governance"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mass pay N->1"])},
    "merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merge accounts N->1"])}
  },
  "merchant": {
    "make_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment gateway"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
    "to_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to the algorand address"])},
    "please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
    "matching_symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matching symbol"])},
    "network_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network fee"])},
    "pay_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay using QR code"])},
    "pay_nativewallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay using algorand wallet"])},
    "pay_webwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay using web wallet"])},
    "cancel_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel payment"])},
    "payment_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment received"])},
    "go_back_to_merchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back to merchant"])},
    "waiting_for_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for the payment"])},
    "error_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error fetching asset: "])}
  },
  "gateway": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free opensource payment gateway without middleman"])},
    "perex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start accepting algo, USDC, USDT or other assets on your website. By accepting cryptocurrency without financial middleman you will increase your sales on your website and increase your profit."])},
    "how": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works?"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create unique matching symbol, eg. order id"])},
    "redirect_to_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect user to the gateway"])},
    "customer_pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer pays directly to your algorand account"])},
    "customer_redirected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer will be redirected to your website back after successful payment"])},
    "use_your_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use your money. You can echange USDT and take USD from the exchange, or you can pay with USDT for other goods or services"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is it secure?"])},
    "you_make_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You make the payment details. You setup the successful and cancel URL address. We notify you when the payment is done. You have to check in your backend where you accept the payment that it has been received and you notify the customer."])},
    "opensource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This project is open source. You can clone, modify it, or run in your private environment for commercial purposes"])},
    "just_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment gateway is just HTML website with javascript. No other backend is used other than official algorand nodes."])},
    "no_restrictions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have AWallet account in order to use the gateway."])},
    "allowed_parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed parameters"])},
    "parameters_perex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can set any of these parameters. Only required field is the address where you want to receive your payment and transaction amount. The payment address consits of 2 parts. Payment info, and gateway settings. Both fields are base64 encoded separated by /. Please make sure that you url encode them properly. Payment info is the basic algorand protocol address used for example in the QR codes."])},
    "payment_info_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed parameters"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["use the asset base unit. Eg. 1 ALGO = 1000000"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the amount in microalgos. Eg. 0,001 ALGO = 1000"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network for debug purposes"])},
    "settings_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed settings"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL address where to redirect client after successful payment"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL address where to redirect client after he clicks cancel payment"])},
    "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example"])},
    "turn_on_build_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn on the build tool"])},
    "turn_off_build_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn off the build tool"])},
    "tether": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tether mainnet"])},
    "algorand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorand"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can specify asset id for the transaction"])},
    "error_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in at least account, amount and asset"])},
    "error_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL address must start with https:// protocol"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generated button"])},
    "payment_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment info"])},
    "callback_configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback configuration"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generated link"])},
    "html_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML link example"])}
  },
  "receive": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvang transactie"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvang transactie naar adres"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notitie"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatie over decimalen voor een QR"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesuggereerd label voor ontvangst adres"])},
    "noteeditable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notitie is bewerkbaar door gebruikber"])}
  },
  "acc_overview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account overzicht"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een betaling"])},
    "receive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvang betaling"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag"])},
    "amount_without_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag zonder wachtende beloningen"])},
    "rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beloningen"])},
    "pending_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtende beloningen"])},
    "reward_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belonings basis"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ronde"])},
    "apps_local_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicaties locale staat"])},
    "apps_total_schema": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicaties totale schema"])},
    "assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activaten"])},
    "created_apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemaakte applicaties"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "multisignature_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisignature drempel"])},
    "multisignature_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisignature addressen"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ververs"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacties"])},
    "no_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen transacties gevonden"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijd"])},
    "tr_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zender"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvanger"])},
    "receiver_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvanger beloningen"])},
    "confirmed_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigde ronde"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kost"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
    "delete_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
    "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this account from your wallet?"])},
    "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this account"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account has been deleted"])},
    "asset_optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in to asset"])},
    "ask_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask question"])},
    "rekey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekey account"])},
    "rekeyedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekeyed to account"])},
    "account0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ledger primary account"])},
    "slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account at ledger slot"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect"])},
    "payment_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment gateway"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
    "no_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no assets yet on this account"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "pay_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send money or tokens to other accounts in the network."])},
    "swap_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can swap one token to another using DEX aggregator services"])},
    "rekey_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are afraid that your account private key may be compromise, you can rekey your account to new private key and keep your original address safe."])},
    "asset_optin_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to receive asset on algorand network you have to open the account for that token."])},
    "receive_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can ask your customers or friends to send you money. This page will generate payment QR code so that others can do it on one click."])},
    "export_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can generate shamir backup mnemonics or algorand mnemonic for accounts with private keys."])},
    "arc14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC 14 Authentication"])},
    "arc14_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC 14 is authentication standard utilizing signed transactions. You can generate the authentication tokens on this page."])},
    "account_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account actions"])},
    "asset_optinArc200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OptIn to ARC200 asset"])},
    "asset_optinArc200_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can watch on your account the ARC200 assets. ARC200 assets are smart contracts with feature that anyone can opt in user's account to be able to receive the asset. ARC200 assets may be dangerous and you should require trustworthy audit of the smart contract so that you can fully trust it."])},
    "scheduled_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled payment"])},
    "scheduled_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can setup and manage a scheduled periodic payments to another accounts"])}
  },
  "acc_overview_assets": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "search_by_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by name"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "search_by_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by asset type"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
    "search_by_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by asset id"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "search_by_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by amount"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
  },
  "optin": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept transfers of asset in account"])},
    "searchById": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I know the asset ID"])},
    "assetId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset ID"])},
    "assetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset Name"])},
    "searchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find asset"])},
    "assetInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about the asset"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "unit_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit name"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimals"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "metadata_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata hash"])},
    "default_frozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default frozen"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator address"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager address"])},
    "clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clawback address"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeze address"])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve address"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "optin_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in"])},
    "dialog_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in to asset"])},
    "optin_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to pay 0.001 algo to opt in to this asset? After you opt in, you will be able to receive this asset to your account."])},
    "optin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to opt in"])},
    "asset_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset not found"])},
    "asset_opt_in_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully opt in for the asset"])}
  },
  "accounts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts overzicht"])},
    "no_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen accounts gevonden"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account naam"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaal"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect"])},
    "show_on_netowork_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show accounts for network"])},
    "2fa_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA code"])}
  },
  "newacc": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw account"])},
    "create_basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een basis account"])},
    "import_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importeer account"])},
    "create_multisign_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een multisignature account"])},
    "watch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk account"])},
    "write_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schrijf uw 25 woorden geheugensteuntje op."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intern account naam"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak account"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "multisig_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een multisignature account kan alleen transacties vormen als N accounts vermeld bij het maken van uw account de transactie tekenen."])},
    "select_account_from_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer bestaande accounts in uw portemonnee"])},
    "add_other_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg uw vrienden toe - een account per regel"])},
    "trashold_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer hoeveel accounts moeten tekenen"])},
    "create_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u een account wilt maken moet u uw 24 woorden geheugensteuntje opschrijven. Is het veilig om hem nu te laten zien?"])},
    "show_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon gegeuhensteun"])},
    "position_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat is het woord op de n.e positie"])},
    "mnemonic_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schrijf uw geheugensteun woorden in getoonde volgorde op, kopieer ze of maak er een foto van. De volgende stap zal zijn om enkele woorden uit deze zin te bevestigen en wij zullen het opslaan in de gecodeerde opslag in uw browser. Het is veilig om deze geheugensteun op te slaan en dit adres te gebruiken zonder het op de account op te slaan. Zorg ervoor dat je de geheugensteun zin nooit verliest."])},
    "start_challenge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start de uitdaging"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak nieuw"])},
    "hide_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verberg geheugensteun"])},
    "drop_phrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laat zin vallen"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan from QR"])},
    "create_vanity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create vanity account"])},
    "vanity_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address will start with"])},
    "vanity_mid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address will contain"])},
    "vanity_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address will end with"])},
    "vanity_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of addresses processed"])},
    "vanity_button_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find the address"])},
    "vanity_button_stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop searching"])},
    "vanity_workers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of threads to use"])},
    "vanity_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use this account"])},
    "auto_lock_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto lock feature is turned off"])},
    "ledger_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HW Wallet - Ledger Account"])},
    "wc_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet Connect Account"])},
    "twofa_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA Account"])},
    "show_qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show QR Code"])},
    "email_pass_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email & Password account"])},
    "shamir_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir backup recovery"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New account actions"])},
    "create_basic_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorand basic account is ED25519 account with private key. This method uses your computer random number generator to create secret for your account."])},
    "ledger_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One of most secure solutions is to handle the secret key generation by specialized device. This account utilizes Ledger device to create your account and handle the signing operations."])},
    "wc_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can connect to your friend's wallet using the wallet connect account type. You can send them request to sign the message for example as part of multisig."])},
    "twofa_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two factor account is multisig account, where one account is account of your choosing for example ledger account or email password account, second account is recovery account - ideally account which is not in the same secure storage, and third account is the two factor account from the server which signs transactions when you provide correct two factor pin code."])},
    "email_pass_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC76 Email & Password account allows you to generate algorand account from any text. The text is hashed and generates 32 bytes as seed for your algorand account. This may be alternative to other types of random number generators in your computer or hw device which you can utilize in two factor account or generic multisig account."])},
    "create_vanity_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can brute force nice account address for your ED25519 account. This method simply tries a lot of combinations of creating account and lets you pick first or last letters of the account address."])},
    "create_multisign_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisig accounts are the most secure accounts. If used in combination of multiple ledger devices and different random number generators, this provides enterprise level of security which is hardcoded directly to the algorand blockchain."])},
    "watch_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can track any account on the network."])},
    "import_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can recover the account from the algorand recovery mnemonic."])},
    "shamir_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can recover the account from the shamir backup recovery mnemonics."])},
    "create_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your first account"])},
    "no_accounts_at_network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no accounts for selected network"])},
    "basic_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic options"])},
    "advanced_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced options"])},
    "skip_challange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip challange"])},
    "new_account_challange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New account challange"])},
    "click_to_show_positions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to show positions"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])}
  },
  "arc76account": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email and password account (ARC-0076)"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash of combination of your email and your password will generate seed bytes for your first algorand account. You can change the password or email only by rekeying to another account."])},
    "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC-0076 standard will be used with algorithm PBKDF2-999999-SHA256. We recommend the password length to be at least 50 characters long. This type of account may be served as secondary type of random number generator and may strengthen your security. Please keep the secret secure and do not reuse it."])},
    "arc_draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC-0076 is in the DRAFT state. The specification can change. Please consider this implementation for experimental usage."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "select_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select very very very secure password."])},
    "save_password_switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save password"])},
    "password_not_stored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch out. We do not store your password. We will store only the account public address and email to the secure storage. When you will be doing transactions with this account you will be prompted to enter the password."])},
    "gui_not_implemented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GUI for signing transaction without stored password is not currently implemented."])}
  },
  "import": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importeer portemonee"])},
    "wallet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portemonnee naam"])},
    "wallet_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portemonnee bestand"])},
    "import_wallet_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importeer portemonnee"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt eerder geëxporteerde portemonnees importeren. Het wachtwoord van de portemonnee blijft in de geëxporteerde portemonnee, zodat u deze na het importeren kunt openen met hetzelfde wachtwoord als voorheen. U kunt het wachtwoord wijzigen nadat u zich hebt aangemeld via de instellingenpagina."])}
  },
  "acc_type": {
    "basic_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basis account"])},
    "multisig_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisignature accout"])},
    "public_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publiek account"])},
    "rekeyed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekeyed account"])},
    "ledger_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HW ledger account"])},
    "wc_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect account"])}
  },
  "account": {
    "select_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select account"])}
  },
  "pay": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een betaling - van"])},
    "toggle_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle camera"])},
    "note_is_b64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notitie is Base64 gecodeerd. Indien ingeschakeld kunt u base64 invoer in het notitieveld plaatsen en het als onbewerkte gegevens naar het netwerk sturen."])},
    "selected_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geselecteerd account"])},
    "multisig_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisignature account"])},
    "create_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorstel maken"])},
    "sign_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teken & verstuur voorstel"])},
    "signature_from_friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul hier de handtekening van uw vriend in"])},
    "load_multisig_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laad de multisignature data"])},
    "pay_to_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstuur naar portemonnee account"])},
    "pay_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstuur naar ander account"])},
    "store_other_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het is beter om de rekening waarnaar u geld stuurt in het adresboek op te slaan"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergoeding"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notitie"])},
    "review_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herzie betaling"])},
    "review_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herzie uw betaling svp."])},
    "from_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Van account"])},
    "pay_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstuur naar account"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal"])},
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactie details"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
    "first_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EersteRonde"])},
    "last_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TweedeRonde"])},
    "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GenesisID"])},
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])},
    "to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar"])},
    "signatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handtekeningen"])},
    "signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ondertekend"])},
    "not_signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet ondertekend"])},
    "sign_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teken met"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onderteken"])},
    "send_to_other_signators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stuur deze gegevens naar andere ondertekenaars"])},
    "send_to_network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stuur naar het netwerk"])},
    "state_sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaling wordt naar het netwerk verstuurt"])},
    "state_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaling naar het network verstuurd. Tx: "])},
    "state_waiting_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtend voor netwerk confirmaties."])},
    "state_confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmaties zijn ontvangen, uw betaling is in het block!"])},
    "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactie"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activaten"])},
    "state_error_not_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De betaling heeft het netwerk waarschijnlijk niet bereikt. Bent u offline? Controleer uw account"])},
    "set_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set maxium"])},
    "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environment"])},
    "pay_to_address_malformed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Pay To seems to be malformed."])},
    "asset_failed_to_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It seems that desired asset has failed to load"])},
    "asset_too_small_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It seems your balance is below desired pay amount"])},
    "rekey_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekey to account"])},
    "rekey_to_wallet_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekey to wallet account"])},
    "rekey_to_external_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekey to external account"])},
    "rekey_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekey account"])},
    "rekey_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please take a special attention to this transaction. From now on, the account will use different private key to be able to do the transactions. If you do not have private key of the new account, you will most likely loose ability to sign transactions."])},
    "combine_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combine signatures from other signator"])},
    "combine_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combine signatures"])},
    "return_to_wc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to WalletConnect"])},
    "select_account_for_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select account from which you want to make the payment"])},
    "create_multisig_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create multisig proposal"])},
    "2fa_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA code"])},
    "sign_arc14_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign authorization request"])},
    "toggle_send_to_others_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle Send to others form"])},
    "toggle_combine_with_others_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle Combine with others form"])},
    "process_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process payment"])},
    "tx_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction type"])}
  },
  "settings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])},
    "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server"])},
    "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omgeving"])},
    "mainnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mainnet"])},
    "testnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testnet"])},
    "devnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reach Devnet"])},
    "sandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandbox"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["host"])},
    "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["token"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portemonnee wachtwoord"])},
    "oldpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oud wachtwoord"])},
    "newpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw wachtwoord"])},
    "repeatpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw wachtwoord - herhalen"])},
    "backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup portemonnee"])},
    "backup_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt een back-up van uw portemonnnee maken en importeren naar een andere computer."])},
    "create_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak backup"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder uw portemonnee"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sterkte"])},
    "updated_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord is geupdate"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom server settings"])},
    "protocol_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register protocol handler"])},
    "protocol_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register web+algorand:// handler"])},
    "protocol_change_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully updated the protocol handler"])},
    "dev_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show information for developers"])},
    "update_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update password"])}
  },
  "transaction": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactie details"])},
    "tr_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tramsactie ID"])},
    "tr_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactie type"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notitie"])},
    "note_raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruwe data"])},
    "note_decoded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gedecodeerde data"])},
    "tr_close_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluit beloningen"])},
    "closing_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluitend bedrag"])},
    "confirmed_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigde ronde"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergoeding"])},
    "first_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eerste geldig"])},
    "genesis_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis id"])},
    "genesis_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis hash"])},
    "intra_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intra-ronde afwijking"])},
    "last_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste geldig"])},
    "payment_tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalings transactie"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag"])},
    "close_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluitend bedrag"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvanger"])},
    "receiver_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvanger beloning"])},
    "round_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ronde tijd"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzender"])},
    "sender_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzender beloning"])},
    "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ondertekening"])},
    "created_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemaakt activa"])},
    "asset_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activa naam"])},
    "asset_creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activa maker"])},
    "asset_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activa manager"])},
    "asset_reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activa reserve"])},
    "asset_freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activa vriezen"])},
    "asset_clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activa terugvordering"])},
    "asset_unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activa eenheid naam"])},
    "asset_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activa totaal"])},
    "asset_decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activa decimalen"])},
    "asset_defaultfrozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activa standaard bevroren"])},
    "asset_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activa url"])},
    "asset_metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activa metadata"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijd"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group ID"])}
  },
  "privacy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy beleid"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welke privé-informatie over mij verzamel je?"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen. Behalve IP-adres en browser, die voor elke internetdienst in logboeken wordt opgeslagen. Wij verzamelen geen enkele vorm van prive-informatie zoals uw naam, email of adres."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat sla je op in cookies?"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niets. Wij gebruiken geen cookies. We gebruiken persistente lokale opslag genaamd indexdb voor het bewaren van uw portemonnee."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe verzamel je analytische informatie?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dat doen we niet. Wij gebruiken geen enkele vorm van analytics service bv google analytics, zodat u zich veiliger kunt voelen. Het web is puur html met javascript, en wij kennen uw IP adres alleen als u de server opvraagt. Afhankelijk van uw instellingen en uw algod server of indexer, kan de geselecteerde server ook uw IP-adres weten."])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heeft het gebruik van deze dienst invloed op de advertenties die ik zie?"])},
    "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee. Commerciële servers hebben geen informatie van ons over enig gebruik. De enige manier waarop bijvoorbeeld google kan weten dat u deze dienst gebruikt is dat u deze site bezoekt via google zoekopdracht."])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie verleent deze dienst?"])},
    "a5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scholtz & Company, jsa heeft deze dienst gecreëerd. Bedrijfs ID: 51 882 272, BTW ID: SK2120828105, gevestigd in Bratislava, Slowakije.  Als dit draait op de officiële website a-wallet.net, is het bedrijf ook dienstverlener. Het bedrijf is niet aansprakelijk voor de dienst, noch voor fouten in de toepassing."])}
  },
  "assetcreate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw NTF / ASA"])},
    "show_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon geavanceerd formulier"])},
    "assetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vriendelijke name"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maker adres"])},
    "hide_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verberg geavanceerd formulier"])},
    "creator_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak adres of importeer adres eerst"])},
    "default_fronzen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevroren : Gebruikersaccounts moeten worden gedeblokkeerd alvorens transacties te verrichten"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimalen: geheel aantal decimalen voor berekening activa-eenheid"])},
    "totalIssuance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal aantal van dit goed beschikbaar voor circulatie"])},
    "unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikt om activa eenheden te tonen aan de gebruiker"])},
    "assetURL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionele string die verwijst naar een URL met betrekking tot het activa"])},
    "assetMetadataHash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionele hash-verbintenis van een soort met betrekking tot het goed. Base64 format."])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheerder kan beheerder beamen, reserve, bevriezing en terugvorderingsrekening voor het goed wijzigen."])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve adres: Gespecificeerd adres wordt beschouwd als de activareserve (het heeft geen speciale voorrechten, dit is slechts informatief)"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevries adres: Gespecificeerd adres kan tegoeden van gebruiker bevriezen of deblokkeren"])},
    "clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terugvordering-adres: Gespecificeerd adres kan tegoeden van gebruikers intrekken en naar andere adressen sturen"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerking ingesteld in de aanmaak transactie"])},
    "create_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak NFT / ASA"])}
  },
  "new_account_ledger": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Ledger account"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ledger is a physical device - HW wallet which can store your private key. The maximum security for storing the assets on algorand is multisig account with all signators secured by ledger devices."])},
    "last_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last error"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect ledger and load algorand address"])},
    "slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slot"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "primary_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary address"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account name"])},
    "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save the address to the wallet"])}
  },
  "faq": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veelgestelde vragen"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de portemonnee"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo wallet applicatie is in eerste instantie gemaakt door Scholtz & Company, jsa. Open source, html only, coroporate vriendelijk, en veiligheid zijn de belangrijkste voordelen voor het gebruik ervan."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe is mijn wallet data beveiligd?"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw gegevens zijn alleen in de browser. Het wordt opgeslagen in een persistente index db. Elke portemonnee is versleuteld met aes256 met uw wachtwoord. Als u een sterk genoeg wachtwoord maakt, zal uw portemonnee nooit worden gebruteforceerd. Het wachtwoord verlaat uw computer niet en bevindt zich alleen in de cache van de browser. Na 5 minuten na het inloggen wordt de cache vernietigd."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaat het wachtwoord van mijn portemonnee naar het internet?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nooit"])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe kan ik het wachtwoord van de portefeuille wijzigen?"])},
    "a4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in en krijg toegang tot uw portemonnee"])},
    "a4_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigeer naar instellingen."])},
    "a4_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ oud wachtwoord, nieuw wachtwoord, en bevestig nieuw wachtwoord."])},
    "a4_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstuur formulier en uw wachtwoord wordt gewijzigd."])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan ik mijn portefeuille naar een andere computer verplaatsen?"])},
    "a5_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen en toegang tot uw portemonnee"])},
    "a5_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigeer naar instellingen."])},
    "a5_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga naar sectie back-up en sla uw gegevens op in het lokale bestand."])},
    "a5_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga in de andere computer naar nieuwe portemonnee, importeer portemonnee, en upload het gedownloade bestand naar de andere browseropslag."])},
    "a5_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krijg toegang tot de portemonnee met hetzelfde wachtwoord als voorheen. U kunt het wachtwoord wijzigen in de instellingen."])},
    "q6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan ik uw broncode bekijken?"])},
    "a6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja. https://github.com/scholtz/wallet/ Deze wallet is open source gemaakt en is vrij om te gebruiken, te kopiëren of aan te passen, zelfs voor commerciële doeleinden. Als u in staat bent om deel te nemen aan het project, wees dan zo vriendelijk en maak een pull request aan met uw verbetering."])},
    "q7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe kan ik het project helpen?"])},
    "a7_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donatie aan algorand adres AWALLETCPHQPJGCZ6AHLIFPHWBHUEHQ7VBYJVVGQRRY4MEIGWUBKCQYP4Y zal het project op gang helpen. Het project is niet gefinancierd door algorand inc noch door algorand foundation."])},
    "a7_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om het project te vertalen of te helpen met de vertaling, ga naar https://github.com/scholtz/wallet/tree/master/src/locales, kopieer het vertaalbestand naar jouw taal en doe een pull-verzoek."])},
    "a7_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als je een programmeur bent, doe dan alsjeblieft een code review, of help ons op welke manier dan ook waarvan je denkt dat dit project beter zou moeten zijn."])},
    "q8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe maak je een rekening met meerdere handtekeningen?"])},
    "a8_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak eerst de accounts aan met de private sleutels of maak publieke accounts aan. Het is beter om ze namen te geven die je zult onthouden voor het doel ervan."])},
    "a8_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga naar nieuw adres aanmaken en selecteer adres met meerdere handtekeningen."])},
    "a8_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer alle accounts die de transacties kunnen ondertekenen en selecteer hoeveel handtekeningen minimaal zijn voor het netwerk om de multisig-betaling te accepteren."])},
    "a8_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadat u de multisignature-rekening hebt aangemaakt, kunt u deze openen via de rekeningenlijstpagina."])}
  },
  "connect": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect to DApps using WalletConnect"])},
    "uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WalletConnect URI"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
    "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect"])},
    "sendBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send back to DApp"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "authaddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorized Address"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Index"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "rekeyto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekey To"])},
    "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method"])},
    "client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client ID"])},
    "request_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request ID"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "peer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peer"])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected"])},
    "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions"])},
    "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
    "total_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Fee"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign"])},
    "clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect from clipboard"])},
    "sign_txs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please sign individual transactions"])},
    "signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signed"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validity"])},
    "rounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rounds"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Index"])},
    "app_args": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Args"])},
    "app_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Accounts"])},
    "app_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Foreign Assets"])},
    "boxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boxes"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis ID"])},
    "genesis_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis Hash"])},
    "session_proposals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session proposals"])},
    "toggle_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle camera"])},
    "init_wc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initialize connection to Wallet Connect"])},
    "sign_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign all"])},
    "proposer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proposer"])},
    "all_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use all wallet accounts"])}
  },
  "new_account_wc": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add WalletConnect account"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account name"])},
    "last_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last error"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan the QR code"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save the address to the wallet"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy URI to clipboard"])},
    "show_qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show QR code"])}
  },
  "new_account_2fa": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA Account"])},
    "help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA account allows you to create multisig account with 3 accounts setup with threshold of 2 required for signature."])},
    "help2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First account may be password protected account where in the case of the hack of your wallet the hacker cannot sign the transactions on your behalf. If your 2FA device gets broken, or 2FA service gets down, you can rekey your account to another 2FA multisig with your first and second account."])},
    "help3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA service is third party service which might be compromised. If hacker receives your private key and receives the private key of the 2fa service, he may sign transactions on your behalf. By using the 2fa service you increase chance that hacker who receive ability to sign transactions with your primary or recovery account is not able to sign transactions from your multisig account."])},
    "2fa_provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA provider"])},
    "hot_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hot account"])},
    "cold_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cold storage account"])},
    "request_qr_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request 2FA QR code"])},
    "scan_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please scan the QR code with your 2FA app"])},
    "manual_entry_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual entry key"])},
    "confirm_2fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm setup"])},
    "confirm_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please write 2FA code from your auth app to confirm the setup. After confirmation it is not possible to request the 2fa secret code again."])},
    "2fa_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA code"])},
    "save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save address"])}
  },
  "onlineofflinedialog": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protect the network with your account's balance"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can select public participation node in order to verify the blocks in the network. You will generate new participation keys at the public algod server and sign the transaction to allow these participation keys collect blocks data. This will cost you as one transaction on the network (0.001 algo). Online account state does not mean that your private key is exposed to the internet. It means that your account is registered at the online algod node which is protecting blockchain."])},
    "makeOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make account online"])},
    "makeOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make account offline"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algod host"])}
  },
  "new_account_shamir": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir backup account recovery"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir backup is the method to split mnemonic into N parts where M accounts is the recovery threshold."])},
    "mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mnemonic"])},
    "add_mnemnic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add mnemonic"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the account"])},
    "recover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recover"])}
  },
  "account_export": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export account"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On this page you can export your private key in form of algorand's mnemonic or shamir backup."])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide your wallet password"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "algo_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorand mnemonic"])},
    "shamir_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir backup"])},
    "toggle_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle QR code"])},
    "recovery_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery threshold"])},
    "number_of_mnemonics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of mnemonics"])},
    "generate_shamir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate shamir mnemonics"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shamir Index"])},
    "shamir_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please secure mnemonic at very secure place. Make sure you write down all parts of shamir backup and do not store the recovery threshold at one place."])},
    "algo_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please secure mnemonic at very secure place."])},
    "dev_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developer info"])}
  },
  "arc200": {
    "optin_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add ARC 200 asset to account"])},
    "app_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARC200 App ID"])},
    "fetch_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fetch asset information"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "examples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example assets"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbol"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimals"])},
    "supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total supply"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account balance"])},
    "is_opted_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account can receive asset"])},
    "create_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create box for this asset for this account"])},
    "save_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save asset to your account"])}
  },
  "scheduled_payments": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled payments management"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With decentralized scheduler you will deploy the smart contract - escrow account which will be used for purpose to allow any executor perform withdrawal from your escrow account to the destination account when the time comes. Executors are incentivized to execute your scheduled task with vision of receiving small fee paid in ASA.Gold token - 0.001 gram of gold."])},
    "new_scheduled_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New scheduled payment"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "pay_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay to"])},
    "send_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset to send"])},
    "create_escrow_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create escrow account"])},
    "list_of_payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of active payments"])},
    "no_payment_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No scheduled payments has been found"])},
    "app_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escrow app id"])},
    "app_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escrow app address"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "fee_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee balance"])},
    "execution_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution fee"])},
    "description_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this detail overview of the scheduled payment you can see the current asset balance of the escrow account and you have actions to deposit more assets to the escrow account or to executors."])},
    "details_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled payment details"])},
    "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seconds"])},
    "deposit_fee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit asa.gold token to fee pool smart contract"])},
    "fee_asset_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution fee token"])},
    "amount_to_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to deposit to fees"])},
    "get_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get more tokenized gold"])},
    "list_of_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of assets at escrow account"])},
    "assets_loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset information is loading"])},
    "asset_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset ID"])},
    "asset_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset name"])},
    "asset_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset amount"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
    "optin_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escrow account optin action"])},
    "optin_to_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in to asset"])},
    "optin_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in"])},
    "withdraw_asset_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw from escrow account"])},
    "withdraw_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset to withdraw"])},
    "withdraw_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to withdraw"])},
    "withdraw_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execute withdrawal"])},
    "load_script_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load app execution profile"])},
    "load_click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load"])},
    "xgov_promo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This work has been performed with support from the Algorand Foundation xGov Grants Program"])}
  }
}